import { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";

function Home() {
  let [searchParams] = useSearchParams();
  let [platform] = useState(searchParams.get("platform"));

  const iosUrl = "https://apps.apple.com/se/app/greetie/id6502982730";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.greetie.prod";
  console.log(platform);

  useEffect(() => {
    if (platform === "ios") {
      window.location.href = iosUrl;
    } else if (platform === "android") {
      window.location.href = androidUrl;
    }
  }, [platform]);

  return (
    <Container maxWidth="xs">
      <Grid container sx={{ mx: "10px", my: "5px" }}>
        <Grid item xs={6}>
          <a href={androidUrl} target="_blank" rel="noreferrer">
            <img
              alt="Ladda ned på Google Play"
              width={150}
              src="https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png"
            />
          </a>
        </Grid>
        <Grid item xs={6} sx={{ py: "5px" }}>
          <a href={iosUrl} target="_blank" rel="noreferrer">
            <img
              src="/assets/Download_on_the_App_Store_Badge_SE_RGB_blk_100317.svg"
              width={150}
              height={46}
              alt="Ladda ner från App Store"
            />
          </a>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
